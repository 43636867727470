import Vue from "vue"
import { Quasar } from "quasar"
import VueI18n from "vue-i18n"
import messages from "src/i18n"

Vue.use(VueI18n)

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  }
}

const fullLocale = Quasar.lang.getLocale()
const locale = fullLocale.substring(0, 2)

const i18n = new VueI18n({
  numberFormats,
  messages,
  locale,
  fallbackLocale: "en"
})

export default ({ app }: any) => {
  app.i18n = i18n
}

export { i18n }
