






import { Mixins, Component } from 'vue-property-decorator'
import PermissionsMixin from 'src/mixins/permissions-mixin'

@Component
export default class App extends Mixins(PermissionsMixin) {
  created() {
    this.refreshSession()
  }

  refreshSession() {
    const hour = 60 * 60 * 1000
    try {
      setInterval(async () => {
        const cognitoUser = await this.$Auth.currentAuthenticatedUser()
        const currentSession = cognitoUser.signInUserSession
        cognitoUser.refreshSession(
          currentSession.refreshToken,
          (session: any) => {
            console.log(session)
          }
        )
      }, hour)
    } catch (e) {
      console.log('Unable to refresh Token', e)
    }
  }
}
