const aws = { 
	 aws_project_region : 'eu-west-1',
	 aws_cognito_identity_pool_id: 'eu-west-1:f28fcc29-249b-431c-a8a5-cc457f71a45c',
	 aws_cognito_region: 'eu-west-1',
	 aws_user_pools_id:'eu-west-1_73eJTSv3g',
	 aws_user_pools_web_client_id: '7efpjv9culjc2aobcbcdn08a0o',
	 aws_appsync_graphqlEndpoint: 'https://4lnnvo6j5zadhogadckxiyi3m4.appsync-api.eu-west-1.amazonaws.com/graphql',
	 aws_appsync_portal_graphqlEndpoint: 'https://xogmhtoobjd2do55gowmcm2z24.appsync-api.eu-west-1.amazonaws.com/graphql',
	 aws_appsync_region: 'eu-west-1',
	 aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	 aws_appsync_apiKey: null,
	 oauth: {},
	 Auth: {
	 	 identityPoolId: 'eu-west-1:f28fcc29-249b-431c-a8a5-cc457f71a45c',
	 	 region: 'eu-west-1',
	 	 userPoolId: 'eu-west-1_73eJTSv3g',
	 	 userPoolWebClientId: '7efpjv9culjc2aobcbcdn08a0o'
	} 
} 
  export default aws
